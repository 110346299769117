import React, { useEffect } from "react";
import Layout from "@herb/layouts";
import { Box } from "@herb/ui";
import IndutryImages from "@herb/components/IndutryImages";
import SeoImageSeriveSection from "@herb/components/SeoImageSeriveSection";
import MarketingPlanDispensary from "../../components/MarketingPlanDispensary";
import SeoQuoteDispensary from "../../components/SeoQuoteDispensary";
import Fade from "react-reveal/Fade";
import Button from "@herb/components/Button";
import MarketingSolutionForDispensaryPlus from "../../components/MarketingSolutionForDispensaryPlus";
import DispensaryHelp from "../../components/DispensaryHelp";
import SEO from "@herb/components/seo";
import DispensaryImagesScroll from "../../components/DispensaryImageScroll";
import { deployUrl } from "@herb/utils";
import { scriptCreate as aggleScript } from "@herb/utils/scripts";
import "./index.css";
import { Helmet } from "react-helmet";
const isBrowser = typeof window !== "undefined";
const DispensaryPlus = props => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;

    document.body.appendChild(script);
    scriptCreate();
    aggleScript();

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  function scriptCreate() {
    if (isBrowser) {
      setTimeout(() => {
        const scriptFunction = document.createElement("script");
        scriptFunction.type = "text/javascript";
        scriptFunction.innerHTML = `
        !function(e,s){
          (o=s.createElement('script')).src='https://s.opensend.com/os.min.js',o.async=!0,o.setAttribute('ostyp','6311ae17'),o.setAttribute('osid','BD4E057F'),(t=s.getElementsByTagName('script')[0]).parentNode.insertBefore(o,t)
        }(window,document);`;
        document.body.appendChild(scriptFunction);
      }, 500);
    }
  }

  const SeoQuoteStyles = {
    fontSize: "32px",
    lineHeight: "42px",
    fontWeight: 400,
    textTransform: "uppercase",
    fontFamily: "Source Code Pro",
    fontStyle: "italic",
    "@media screen and (max-width:768px)": {
      fontSize: "24px",
      fontStyle: "italic",
      lineHeight: "32px",
      padding: "56px 15px 0px 15px"
    }
  };

  const DesktopStyle = {
    fontStyle: "normal",
    fontWeight: "400",
    "@media screen and (max-width:480px)": {
      fontStyle: "italic",
      fontWeight: "300"
    }
  };
  const logos = [
    {
      id: 19,
      width: 151,
      height: 122,
      viewBox: "0 0 151 122"
    },
    {
      id: 20,
      width: 101,
      height: 81,
      viewBox: "0 0 101 81"
    },
    {
      id: 21,
      width: 101,
      height: 81,
      viewBox: "0 0 101 81"
    }
  ];

  const dispLogos = [
    {
      id: 19,
      width: 80.77,
      height: 100,
      src:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2023/05/04124134/44f299329eb877eaca6a2a692d2555dd.png"
    },
    {
      id: 20,
      width: 100,
      height: 81.5,
      src:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2023/05/08093250/NR_WTree_Black-3.png"
    },
    {
      id: 21,
      width: 100,
      height: 81,
      src:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2023/05/08093255/20-insa-Logo-BW-SmallUse-1.png"
    },
    {
      id: 19,
      width: 80.77,
      height: 100,
      src:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2023/05/04124134/44f299329eb877eaca6a2a692d2555dd.png"
    },
    {
      id: 20,
      width: 100,
      height: 81.5,
      src:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2023/05/08093250/NR_WTree_Black-3.png"
    },
    {
      id: 21,
      width: 100,
      height: 81,
      src:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2023/05/08093255/20-insa-Logo-BW-SmallUse-1.png"
    },
    {
      id: 19,
      width: 80.77,
      height: 100,
      src:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2023/05/04124134/44f299329eb877eaca6a2a692d2555dd.png"
    },
    {
      id: 20,
      width: 100,
      height: 81.5,
      src:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2023/05/08093250/NR_WTree_Black-3.png"
    },
    {
      id: 21,
      width: 100,
      height: 81,
      src:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2023/05/08093255/20-insa-Logo-BW-SmallUse-1.png"
    },
    {
      id: 19,
      width: 80.77,
      height: 100,
      src:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2023/05/04124134/44f299329eb877eaca6a2a692d2555dd.png"
    },
    {
      id: 20,
      width: 100,
      height: 81.5,
      src:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2023/05/08093250/NR_WTree_Black-3.png"
    },
    {
      id: 21,
      width: 100,
      height: 81,
      src:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2023/05/08093255/20-insa-Logo-BW-SmallUse-1.png"
    }
  ];

  const bussiness1 = [
    {
      heading: "GROW YOUR DISPENSARY REVENUE",
      text:
        "Put your dispensary in front of local-to-you cannabis users with our proprietary targeting system. We are able to zero in on known cannabis shoppers, and can ensure that your dispensary is shown to customers that can actually shop with you.",
      number: 1
    },

    {
      heading: "ADD KNOWN CANNABIS USERS TO YOUR DISPENSARY DATABASE",
      text:
        "We give you the email addresses of local cannabis users for you to contact and market to as you see fit. This list is a treasure trove of potential customers that could become lifetime shoppers at your dispensary. We are the only Cannabis Marketing Service that gives access to first-party customer data. Because we know it could be a game-changer for your dispensary.",
      number: 1,
      text1:
        "We give you the email addresses of local cannabis users for you to contact and market to as you see fit. This list is a treasure trove of potential customers that could become lifetime shoppers at your dispensary.",
      text2: "Because we know it could be a game-changer for your dispensary.",
      boldText1: "We are only",
      coloredText: "Cannabis Marketing Service",
      boldText2: "that gives access to first-party customer data.",
      textNum: 2
    },

    {
      heading: "Become Our Exclusive Dispensary Partner In Your City",
      text:
        "We’ll become your secret weapon for rapid local growth, and you won’t have to worry about us partnering with a competing dispensary in your area. Plus we’ll pull out all the stops to make you #1 in your city.",
      number: 2
    },
    {
      heading: "99% CLIENT SATISFACTION",
      text:
        "Herb works with top brands across the world with a 99% client satisfaction rate.",
      number: 3
    }
  ];

  const bussiness2 = [
    {
      heading: "Big results",
      text: "Clients see an average 2x ROI.",
      number: 4
    },

    {
      heading: "Amazing service",
      text: "We got your back. 94% of clients recommend us.",
      number: 5
    }
  ];

  const helpCenterStyles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "250px",
    background:
      "radial-gradient(50% 50% at 50% 50%, #F9CCCC 0%, rgba(255, 255, 255, 0) 100%)",
    "@media screen and (max-width:500px)": {
      height: "368px"
    }
  };
  const scrolltoMarketingForm = () => {
    document.getElementById("marketing-plan-form").scrollIntoView();
  };

  return (
    <div className="herbPlusBg">
      <Layout>
        <>
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        </>
        <SEO
          title={`Dispensary Plus`}
          url={"/dispensary-plus"}
          image={
            "https://herb-platform-images.imgix.net/wp-content/uploads/2022/07/06093019/Herb-preview-Image.webp?width=500"
          }
          canonical={`${deployUrl}/dispensary-plus`}
        />
        <div
          css={{
            margin: "0 auto",
            maxWidth: "1300px",
            padding: "50px 64px 0 64px",
            "@media screen and (max-width:768px)": {
              padding: "50px 16px 0 16px"
            }
          }}
        >
          <Box
            css={{
              position: "relative",
              backgroundSize: "cover",
              width: "100%"
            }}
          >
            <Box
              css={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingBottom: "124px",
                maxWidth: "1300px",
                position: "relative",
                textAlign: "center",
                margin: "0 auto",
                "@media screen and (max-width:768px)": {
                  paddingBottom: "0"
                }
              }}
            >
              <Box
                css={{
                  maxWidth: "825px"
                }}
              >
                <h1 className="herbPlusHeading">
                  Grow Your Dispensary with Herb
                </h1>
                <p className="herbPlusMainText" css={DesktopStyle}>
                  Be the exclusive Herb dispensary partner IN your city.
                </p>
              </Box>
              <Button type={"herbPlusBtn"} onClick={scrolltoMarketingForm}>
                let’s get started
              </Button>
            </Box>
          </Box>
          <div className="seoQuoteDispensaryMobile">
            <SeoQuoteDispensary
              section={"topSection"}
              author="Dylan, Botany Farms"
              SeoQuoteStyles={SeoQuoteStyles}
            />
          </div>
        </div>

        <div
          css={{
            "@media screen and (max-width:400px)": {
              padding: "0px"
            }
          }}
        >
          <div
            css={{
              margin: "0 auto",
              maxWidth: "1300px",
              padding: "0 64px 0 64px",
              "@media screen and (max-width:768px)": {
                padding: "0 16px 0 16px"
              }
            }}
          >
            <Box
              css={{
                maxWidth: "1200px",
                margin: "0 auto",
                "@media screen and (max-width:768px)": {
                  margin: "80px auto 0 auto"
                }
              }}
            >
              <Fade bottom>
                <h2 className="ambitiousTeamDisp">
                  Trusted by the industry’s most ambitious teams
                </h2>
              </Fade>

              <Box
                css={{
                  maxWidth: "1100px",
                  margin: "0 auto",
                  lineHeight: 5,
                  "@media screen and (max-width:768px)": {
                    // padding: "0 25px"
                  }
                }}
              >
                <IndutryImages list={logos} />
              </Box>
              <div className="seoQuoteDispensary">
                <SeoQuoteDispensary
                  section={"topSection"}
                  author="Dylan, Botany Farms"
                  SeoQuoteStyles={SeoQuoteStyles}
                />
              </div>
            </Box>
            <Box
              css={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "533px",
                "@media screen and (max-width:768px)": {
                  height: "469px"
                }
              }}
            >
              <DispensaryHelp
                heading={"Not a dispensary?"}
                subHeading={
                  "We can help your cannabis brand get more customers."
                }
                buttonText={"Grow Your Cannabis Brand Here"}
                buttonLink={"/herb-plus"}
                css={helpCenterStyles}
              />
            </Box>
            <SeoImageSeriveSection
              page={"dispensaryPlus"}
              heading="#1 in Cannabis Marketing"
              subHeading={"The best way to market your dispensary online."}
              bussiness={bussiness1}
              image1="Group98.png"
              image1Styles={{
                width: "87%",
                height: "580px",
                objectFit: "contain",
                "@media only screen and (max-device-width: 1024px) and (min-device-width: 770px)": {
                  width: "80%"
                },
                "@media screen and (max-width:769px)": {
                  width: "100%",
                  height: "50%"
                }
              }}
            />

            <Box>
              <SeoQuoteDispensary
                section={"bottomSection"}
                author="Matt Muratet - Delta Extrax | Digital Marketing Coordinator"
                SeoQuoteStyles={SeoQuoteStyles}
              />
            </Box>
            <Box
              css={{
                textAlign: "center",
                paddingTop: "146px",

                "@media screen and (max-width:768px)": {
                  paddingTop: "70px",
                  paddingRight: "16px",
                  paddingLeft: "16px"
                }
              }}
            >
              <Fade bottom>
                <h2 className="marketingSolutions">
                  The Most Effective Way To Grow Your Dispensary
                </h2>
              </Fade>
              <Fade bottom>
                <div>
                  <MarketingSolutionForDispensaryPlus />
                </div>
              </Fade>
              <Button type={"herbPlusBtn"} onClick={scrolltoMarketingForm}>
                Let's Get Started
              </Button>
            </Box>
          </div>

          <div
            css={{
              padding: "146px 0 30px 0",
              "@media screen and (max-width:768px)": {
                padding: "50px 0 0 0"
              },
              "@media screen and (max-width:500px)": {
                padding: "80px 0 30px 0"
              }
            }}
          >
            <DispensaryImagesScroll list={dispLogos} direction="right" />
          </div>

          <div
            css={{
              margin: "0 auto",
              padding: "0 64px 50px 64px",
              maxWidth: "1300px",
              "@media screen and (max-width:768px)": {
                padding: "0 16px 50px 16px"
              }
            }}
          >
            <SeoImageSeriveSection
              page={"dispensaryPlus"}
              heading="Your One-Stop Shop For All Things Cannabis Marketing"
              bussiness={bussiness2}
              image1="Dispensary-Boutique Product Page.png"
              image2="Dispensary-Frame 255.png"
              reverse={true}
              text="Our cannabis marketing solution taps into the largest and most engaged community of consumers to deliver better customer experiences."
              textBold="We laser-target cannabis users local to your dispensary, so you don’t waste a cent on consumers that can’t buy from you."
              image1Styles={{
                position: "relative",
                width: "90%",
                height: "490px",
                objectFit: "contain",
                "@media only screen and (max-device-width: 1235px) and (min-device-width: 1025px)": {
                  width: "400px"
                },
                "@media only screen and (max-device-width: 1024px) and (min-device-width: 770px)": {
                  width: "80%"
                },
                "@media screen and (max-width:768px)": {
                  width: "80%",
                  height: "100%"
                }
              }}
              image2Styles={{
                position: "absolute",
                top: "60%",
                right: "0",
                width: "58%",
                height: "490px",
                "@media only screen and (max-device-width: 1024px) and (min-device-width: 770px)": {
                  width: "50%"
                },
                "@media screen and (max-width:769px)": {
                  width: "50%",
                  right: "4%",
                  height: "auto"
                }
              }}
            />
          </div>
        </div>

        <div
          className="lowerBackgroundColor"
          css={{
            display: "flex",
            paddingTop: "146px",
            justifyContent: "center",
            "@media screen and (max-width:769px)": {
              paddingTop: "0px"
            }
          }}
        >
          <MarketingPlanDispensary />
        </div>
      </Layout>
    </div>
  );
};

export default DispensaryPlus;
